import React from 'react';
import { bool, func } from 'prop-types';
import { StyledBurger } from './Burger.styled';

const Burger = ({ burgerColor, open, setOpen }) => {

  function handleClick() {
    setOpen(!open);
    if(!open){
      document.body.classList.add('menu-open');
    }else {
      document.body.classList.remove('menu-open');
    }
  }

  return (
    <StyledBurger burgerColor={burgerColor} open={open} onClick={handleClick}>
    </StyledBurger>
  )
}

Burger.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
};

export default Burger;