import styled from 'styled-components';

export const StyledIntro = styled.div`
    position: relative;
    width: 100%;
    height: 74vh;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    background-repeat: no-repeat;
    background-color: #20221D;
    background-size: cover;
    padding-bottom: 6rem;

    @media (min-width: 768px) {
        height: 38rem;
        align-items: center;
        padding-bottom: 0;
        background-size: contain;
    }

    &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 22em;
        background: -webkit-gradient(linear,left bottom,left top,from(#000),to(hsla(0,0%,100%,0)));
        background: linear-gradient(0deg,#000,hsla(0,0%,100%,0));
        z-index: 1;
        opacity: 1;
    }

    div {
        position: relative;
        z-index: 1;
        margin: 0 1rem;
        text-decoration: none;
        color: white;
        font-size: 2rem;
        font-family: 'Exo', sans-serif;
        width: 100%;

        @media (min-width: 768px) {
            margin-right: 5rem;
            font-size: 4rem;
            width: 50%;
        }

        span {
            color: #f1672a;
        }
    }
`;