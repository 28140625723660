export const clients = [
    {
        client: "Alternative Airlines",
        codename: "alternativeairlines.svg"
    },
    {
        client: "Accredit Solutions",
        codename: "accredit.svg"
    },
    {
        client: "Benchmark",
        codename: "benchmark.svg"
    },
    {
        client: "British Boarding Schools Network",
        codename: "bbsn.png"
    },
    {
        client: "Casenove Loyd",
        codename: "casenoveloyd.jpg"
    },
    {
        client: "Digital Doughnut",
        codename: "digitaldoughnut.svg"
    },
    {
        client: "EAS Advantage Solutions",
        codename: "eas.svg"
    },
    {
        client: "Cooplands",
        codename: "cooplands.svg"
    },
    {
        client: "First Central",
        codename: "firstcentral.jpg"
    },
    {
        client: "John Lewis",
        codename: "johnlewis.svg"
    },
    {
        client: "KIMS Hospital",
        codename: "kims.svg"
    },
    {
        client: "Kompan",
        codename: "kompan.svg"
    },
    {
        client: "Micro Scooters",
        codename: "microscooters.jpg"
    },
    {
        client: "Min Jiang",
        codename: "minjiang.png"
    },
    {
        client: "Optegra",
        codename: "optegra.svg"
    },
    {
        client: "Ricardo",
        codename: "ricardo.svg"
    },
    {
        client: "Royal Garden Hotel",
        codename: "royalgardenhotel.svg"
    },
    {
        client: "Schwarzkopf",
        codename: "schwarzkopf.svg"
    }
];