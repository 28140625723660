export const awards = [
    {
        award: "Tech Track 2019",
        codename: "2019-tech-track.svg"
    },
    {
        award: "Kentico Certified Developer",
        codename: "kentico-certified-developer.png"
    },
    {
        award: "Kentico Site of the Month",
        codename: "kentico-site-of-the-month.png"
    },
    {
        award: "Wirehire 100 B2B Site of the Year",
        codename: "wirehive-100-white.png"
    }
];