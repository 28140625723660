import styled from 'styled-components';

export const StyledVideo = styled.section`
    min-height: 23.75rem;
    height: 55vh;
    width: 100%;
    overflow: hidden;
    text-align: left;
    
    @media (max-width: 768px) {
        .hero--campaign {
            min-height: 23.75rem;
            height: 23.75rem
        }
    }
    @media (max-width: 374px) {
        .hero--campaign {
            min-height: 23.75rem
        }
    }
    
    &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 22em;
        background: -webkit-linear-gradient(bottom,#fff,hsla(0,0%,100%,0));
        background: linear-gradient(0deg,#000,hsla(0,0%,100%,0));
        z-index: 0;
        opacity: .5;
    }

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        z-index: 0;
        opacity: .4;
    }

    .row {
      bottom: 40px;
    }
    
    .hero__aligner {
        padding-bottom: 2em;
        position: relative;
    }
    @media (max-width: 1200px) {
        .hero__aligner {
            padding-bottom: 8em
        }
    }
    @media (max-width: 768px) {
        .hero__aligner {
            padding-bottom: 0;
            padding-top: 0;
        }
    }
    .no-video .hero__aligner {
        padding-bottom: 5em
    }
    .image-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      z-index: -1;
    }
    .image-background img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
    }
    .hero__title {
        font-family: "Lato",Arial,"Lucida Grande",sans-serif;
        font-size: 4.25rem;
        font-weight: 600;
        text-transform: none;
        color: #ffffff;
        padding: 0;
        margin-bottom: 1rem;
    }
    @media (max-width: 1200px) {
        .hero__title {
            font-size: 3rem
        }
    }
    @media (max-width: 768px) {
        .hero__title {
            font-size: 2rem
        }
    }
    .hero__summary {
        font-size: 2rem;
        color: white;
        font-weight: 100;
        margin-bottom: 2rem;
        display: block;
    }
    @media (max-width: 768px) {
        .hero__summary {
            font-size: 1.25rem;
        }
    }
    @media (max-width: 768px) {
        .hero__actions .button:first-child {
            margin-bottom: .5rem;
        }
    }
    .hero__title span {
        display: block
    }
    .hero__title span:nth-child(even) {
        color: #ee2d6c
    }
    .no-video .hero__title span {
        color: #fff
    }
    @media (max-width: 768px) {
        .hero__title span {
            color: #fff
        }
    }
    .hero__background {
        display: none
    }
    .no-video .hero__background {
        display: block
    }
    @media (max-width: 768px) {
        .hero__background {
            display: block
        }
    }
    .hero__video {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        display: block;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: -1;
        background-size: cover
    }
    .no-video .hero__video {
        display: none
    }
    .hero__aligner {
        z-index: 4567;
        height: 100%;
        width: 100%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        vertical-align: middle;
    }
`;