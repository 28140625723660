export const websites = [
    {
        name: "NSC Global",
        codename: "nscglobal",
        summary: "Professional services & managed resourcing organisation operating in 100+ countries.",
        video: true,
        url: "http://nscglobal.com",
        graveyard: true,
        teambuild: false,
        home: true
    },
    {
        name: "NSC Global Jobs",
        codename: "nscglobaljobs",
        summary: "Promoting job vacancies at NSC Global.",
        video: false,
        url: "http://nscglobaljobs.com",
        graveyard: true,
        teambuild: false
    },
    {
        name: "Roselands",
        codename: "roselands",
        summary: "Contemporary city courtyards and suburban gardens landscaping solutions.",
        video: false,
        url: "http://roselandlandscapes.com",
        graveyard: true,
        teambuild: false
    },
    {
        name: "Motive Agency",
        codename: "motive",
        summary: "An agile digital agency designed to do things differently.",
        video: false,
        url: "http://motiveagency.co.uk",
        graveyard: true,
        teambuild: false
    },
    {
        name: "Alternative Airlines",
        codename: "alternativeairlines",
        summary: "Online travel agency website to book all your flights.",
        video: false,
        url: "https://www.alternativeairlines.com",
        teambuild: true,
        size: "large",
        home: true
    },
    {
        name: "EAS",
        codename: "eas",
        summary: "Delivering tailored registration solutions to the events industry across the globe.",
        video: false,
        url: "https://www.eventadv.com",
        teambuild: true
    },
    {
        name: "Accredit Solutions",
        codename: "accredit",
        summary: "Providing award-winning accreditation platforms for the world's most high profile organisations, stadiums and events.",
        video: false,
        url: "https://www.accredit-solutions.com",
        teambuild: true
    },
    {
        name: "KIMS Hospital",
        codename: "kims",
        summary: "The largest independent private hospital in Kent.",
        video: false,
        url: "https://kims.org.uk",
        graveyard: true,
        teambuild: false
    },
    {
        name: "KIMS Hospital Careers",
        codename: "kimscareers",
        summary: "Promoting job vacancies at KIMS Hospital the largest independent private hospital in Kent",
        video: false,
        url: "https://kims.org.uk/careers",
        teambuild: true
    },
    {
        name: "Ricardo",
        codename: "ricardo",
        summary: "Global engineering, environmental and strategic consultancy.",
        video: false,
        url: "https://ricardo.com",
        teambuild: true,
        size: "large"
    },
    {
        name: "Ricardo eStore",
        codename: "estore",
        summary: "Purchase leading automotive and powertrain related publications.",
        video: false,
        url: "https://estore.ricardo.com",
        graveyard: true,
        teambuild: false
    },
    {
        name: "SIAL",
        codename: "sial",
        summary: "Defining innovation in the food, beverage and hospitality industry.",
        video: true,
        graveyard: true,
        teambuild: false
    },
    {
        name: "British Boarding Schools Connected",
        codename: "bbsc",
        summary: "Toolkit for international marketing and recruitment.",
        video: false,
        url: "https://connected.britishboarding.com",
        teambuild: true
    },
    {
        name: "British Boarding Schools Network",
        codename: "bbsn",
        summary: "A network of 210+ British boarding schools and educational agents.",
        video: false,
        url: "https://britishboarding.com",
        graveyard: true,
        teambuild: false
    },
    {
        name: "Digital Doughnut",
        codename: "digitaldoughnut",
        summary: "Portal jam packed with useful information for marketers and business leaders.",
        video: false,
        url: "https://www.digitaldoughnut.com",
        teambuild: true,
        size: "large",
        home: true
    },
    {
        name: "Sovos Fiscal Reps",
        codename: "fiscalreps",
        summary: "Independent specialist indirect tax consultancy.",
        video: false,
        graveyard: true,
        teambuild: false
    },
    {
        name: "TTMC",
        codename: "ttmc",
        summary: "Providing best in class, independently audited, unscripted telemarketing and telesales.",
        video: false,
        url: "https://ttmc.co.uk",
        graveyard: true,
        teambuild: false
    },
    {
        name: "UHV Design",
        codename: "uhv",
        summary: "Vacuum components for heating and manipulation with many items.",
        video: true,
        url: "https://www.uhvdesign.com",
        graveyard: true,
        teambuild: false,
        size: "large",
        home: true
    },
    {
        name: "Kompan",
        codename: "kompan",
        summary: "Playground equipment that is fun and built to last.",
        video: false,
        url: "http://www.smart-playground.com",
        teambuild: true,
        size: "large"
    },
    {
        name: "Optegra",
        codename: "optegra",
        summary: "UK's most trusted eye care specialists.",
        video: false,
        teambuild: true,
        size: "large"
    },
    {
        name: "Schwarzkopf Elite",
        codename: "schwarzkopf",
        summary: "Products designed to improve care, color and styling for all hair types.",
        video: false,
        teambuild: true
    },
    {
        name: "Schwarzkopf Professional",
        codename: "skp",
        summary: "Schwarzkopf styling competition from top salons using Schwarzkopf products.",
        video: false,
        graveyard: true,
        teambuild: false
    },
    {
        name: "First Central",
        codename: "1stcentral",
        summary: "Delivery market-leading motor insurance.",
        video: false,
        teambuild: true
    },
    {
        name: "First Central Careers",
        codename: "1stcentralcareers",
        summary: "Promoting job vacancies at First Central.",
        video: false,
        screenshot: true,
        graveyard: true,
        teambuild: false
    },
    {
        name: "Gatineau",
        codename: "gatineau",
        summary: "Best anti-ageing luxury skincare product experts.",
        video: false,
        url: "https://www.gatineau.co.uk",
        teambuild: true
    },
    {
        name: "Royal Garden hotel",
        codename: "royalgardenhotel",
        summary: "5 star luxury in the heart of London.",
        video: false,
        teambuild: true
    },
    {
        name: "The Cock Inn",
        codename: "cockinn",
        summary: "Countryside pub with a glorious garden and a welcoming team.",
        video: false,
        graveyard: true,
        teambuild: false
    },
    {
        name: "Benchmark Furniture",
        codename: "benchmark",
        summary: "Leading English furniture maker with a deep rooted belief in the value of craft.",
        video: false,
        graveyard: true,
        teambuild: false
    },
    {
        name: "Surrey Satellite Technology",
        codename: "sstl",
        summary: "Manufacture and operation of small satellites.",
        video: false,
        graveyard: true,
        teambuild: false
    },
    {
        name: "Min Jiang",
        codename: "minjiang",
        summary: "Authentic chinese restaurant with stunning views of the London skyline and Hyde Park.",
        video: false,
        url: "http://www.minjiang.co.uk",
        graveyard: true,
        teambuild: false
    },
    {
        name: "Conversis",
        codename: "conversis",
        summary: "Leading supplier of translation and localisation services.",
        video: false,
        graveyard: true,
        teambuild: false
    },
    {
        name: "Conversis Medical",
        codename: "conversismedical",
        summary: "Leadind supplier of translation and localisation services, specialised in medical.",
        video: false,
        graveyard: true,
        teambuild: false
    },
    {
        name: "John Lewis",
        codename: "john",
        summary: "All the resources and guidance to help you to use the brand effectively.",
        video: false,
        graveyard: true,
        teambuild: false
    },
    {
        name: "British Hair",
        codename: "britishhair",
        summary: "Hair care, styling and finishing products produced by top hairdresses.",
        video: false,
        graveyard: true,
        teambuild: false
    },
    {
        name: "Red Arrow Leasing",
        codename: "redarrow",
        summary: "Financing a wide variety of capital equipment to suit business cashflow.",
        video: false,
        graveyard: true,
        teambuild: false
    },
    {
        name: "Somebody Listening",
        codename: "somebodylistening",
        summary: "An area for people to tell their stories anonymously.",
        video: false,
        graveyard: true,
        teambuild: false
    },
    {
        name: "MyBalance",
        codename: "mybalance",
        summary: "A finance management application to help keep on top of your outgoings.",
        video: false,
        graveyard: true,
        teambuild: false
    },
    {
        name: "Cybernate",
        codename: "cybernate",
        summary: "Team of digital freelancers coming together to form an agency.",
        video: false,
        graveyard: true,
        teambuild: false
    },
    {
        name: "MayhemBliz",
        codename: "mayhembliz",
        summary: "A place to share your gaming footage to the world.",
        video: false,
        url: "http://mayhembliz.com",
        teambuild: false,
        size: "large"
    },
    {
        name: "GroundBreakerz",
        codename: "groundbreakerz",
        summary: "Breakdance crew that offers training workshops and opportunites to showcase your moves.",
        video: false,
        url: "http://f9-preview.awardspace.com/groundbreakerz.co.uk/",
        teambuild: false
    },
    {
        name: "Sean Palmer",
        codename: "seanp",
        summary: "Portfolio of my work and knowledge.",
        video: false,
        url: "http://seanp.co.uk/",
        teambuild: false
    },
    {
        name: "Microscooters",
        codename: "microscooter",
        summary: "Explore the incredible range of Micro scooters, available in a range of styles.",
        video: false,
        teambuild: true
    },
    {
        name: "Cazenove Loyd",
        codename: "caz",
        summary: "Online magazine collection of travel notes and stories from the experts at cazenove+loyd.",
        video: false,
        teambuild: true
    },
    {
        name: "Mar I Terra",
        codename: "mariterra",
        summary: "Simply-styled spanish restaurant serving classic tapas.",
        video: false,
        graveyard: true,
        teambuild: false
    },
    {
        name: "Cooplands Bakery",
        codename: "cooplands",
        summary: "The third largest bakery chain with over 160 outlets, offering high-quality baked goods.",
        video: false,
        graveyard: true,
        teambuild: false
    },
    {
        name: "Webastral",
        codename: "webastral",
        summary: "Digital agency, making connections everywhere.",
        video: false,
        graveyard: true,
        teambuild: false
    },
    {
        name: "Beds London",
        codename: "bedslondon",
        summary: "A wide selection of mattresses and beds at competitive prices.",
        video: false,
        graveyard: true,
        teambuild: false
    },
    {
        name: "Villa Nazar",
        codename: "villanazar",
        summary: "Brand new, luxury designed villa located in the Kalamar Bay area of Kalkan.",
        video: false,
        graveyard: true,
        teambuild: false
    },
    {
        name: "Explora Haven",
        codename: "explorahaven",
        summary: "Domiciliary care and training care agency.",
        video: false,
        graveyard: true,
        teambuild: false
    },
    {
        name: "Dental Engineers",
        codename: "dentalengineers",
        summary: "Dental industry experts, offering equipment repair, maintenance and installation.",
        video: false,
        graveyard: true,
        teambuild: false
    },
    {
        name: "Charlie Choys",
        codename: "charliechoys",
        summary: "World buffet restaurants in the best locations in promintent towns.",
        video: false,
        teambuild: true
    },
    {
        name: "Munky Mayhem",
        codename: "munkymayhem",
        summary: "First ever website about the upcoming Timesplitters 4 game.",
        video: false,
        url: "https://munkymayhem.wordpress.com",
        teambuild: false
    }
];

export const getFilteredData = (filter, data) => {
    if (!filter) {
        return data;
    }

    return data.filter(item =>
        item.name.toLowerCase().includes(filter.toLowerCase())
    );
};