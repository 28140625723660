import React from 'react';
import { bool } from 'prop-types';
import { StyledMenu } from './Menu.styled';
import { Link } from 'react-router-dom';

import { FaUser, FaUserFriends, FaLink, FaLinkedin} from 'react-icons/fa';

const Menu = ({ open, setOpen }) => {

  function handleClick() {
    setOpen(!open);
    if(!open){
      document.body.classList.add('menu-open');
    }else {
      document.body.classList.remove('menu-open');
    }
  }

  return (
    <StyledMenu open={open}>
      <ul className="nav__mobile">
        <li><Link to='/websites' onClick={handleClick}>Projects</Link><span>Take a look at the projects I've done.</span></li>
        <li><Link to='/knowledge' onClick={handleClick}>Knowledge</Link><span>Discover my knowledge in the industry.</span></li>
      </ul>
      <div className="skew"></div>
      <footer>
        <div>
          Contact me.<br />
          <a href="mailto:sean.graham.palmer@gmail.com">sean.graham.palmer@gmail.com</a>
        </div>
        <div className="footer__socials">
          <ul>
            <li><a href="https://www.linkedin.com/in/palmersean"><FaLinkedin /></a></li>
          </ul>
        </div>
      </footer>
    </StyledMenu>
  )
}

Menu.propTypes = {
  open: bool.isRequired,
}

export default Menu;