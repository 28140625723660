import React, { Fragment, useState, useEffect } from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
  } from "react-router-dom";

import { websites, getFilteredData } from './data';
import Awards from "../Awards"

import { FaUser, FaUserFriends, FaLink} from 'react-icons/fa';

const Knowledge = () => {

    return (
        <Fragment>
            
        <section className="intro section--padding background--skew-left">
            <div className="container container--flex section--padding background--white">
                <div className="half content-box">
                    <header className="section-header">
                        <h1 className="section-title">Education &amp; Experience</h1>
                    </header>
                    <div className="works">
                        <div className="work">
                            <img src="images/clients/motive.svg" />
                            <div class="we-content">
                                <div class="we-name">Motive Agency</div>
                                <div class="we-title">Head of Digital</div>
                                <div class="we-date">March 2020 - Current</div>
                            </div>
                        </div>
                        <div className="work">
                            <img src="images/clients/alternativeairlines.svg" />
                            <div class="we-content">
                                <div class="we-name">Alternative Airlines</div>
                                <div class="we-title">Senior Front End Developer</div>
                                <div class="we-date">April 2019 - March 2020</div>
                            </div>
                        </div>
                        <div className="work">
                            <img src="images/clients/major.svg" />
                            <div class="we-content">
                                <div class="we-name">Major Digital</div>
                                <div class="we-title">Web Developer</div>
                                <div class="we-date">December 2015 - March 2019</div>
                            </div>
                        </div>
                        <div className="work">
                            <img src="images/clients/pulldigital.svg" />
                            <div class="we-content">
                                <div class="we-name">Pull Digital</div>
                                <div class="we-title">Front End Web Developer</div>
                                <div class="we-date">May 2013 - December 2015</div>
                            </div>
                        </div>
                        <div className="work">
                            <img src="images/clients/ukipme.jpg" />
                            <div class="we-content">
                                <div class="we-name">UKi Media &amp; Events</div>
                                <div class="we-title">Email Marketing Co-ordinator and Front End Developer</div>
                                <div class="we-date">January 2013 - April 2013</div>
                            </div>
                        </div>
                        <div className="work">
                            <img src="images/clients/infotex.svg" />
                            <div class="we-content">
                                <div class="we-name">Infotex</div>
                                <div class="we-title">Front End Web Developer</div>
                                <div class="we-date">January 2008 - December 2012</div>
                            </div>
                        </div>
                        <div className="work">
                            <img src="images/clients/bullsigns.jpg" />
                            <div class="we-content">
                                <div class="we-name">Bull Signs</div>
                                <div class="we-title">Graphic Designer</div>
                                <div class="we-date">January 2007 - January 2008</div>
                            </div>
                        </div>
                    </div>

                    <p><strong>Central Sussex College<br />
                    January - 2009 – 2010</strong></p>

                    <p>IT Practioner Professional – Level 2/3<br />
                    IT Users – Level 2/3</p>

                    <p>Health and Safety in ICT and Contact Centres<br />
                    Develop Personal and Organisational Effectiveness<br />
                    Artwork and Imaging Software<br />
                    Website Software<br />
                    Customer Care</p>

                    <p><strong>Richard Collyer<br />
                    September - 2006 – 2007</strong></p>

                    <p>2D and 3D Visual Communication – (Level 2 – Distinction)<br />
                    Contextual References in Art and Design – (Level 2 – Merit)<br />
                    Explore and Develop Art and Design Ideas – (Level 2 – Distinction)<br />
                    Produce an Art and Design Outcome – (Level 2 – Merit)<br />
                    Working with Graphic Design Briefs – (Level 2 – Distinction)<br />
                    Working with Photography Briefs – (Level 2 – Distinction)</p>

                    <p><strong>Certificate in IT Users (New CLAiT) -</strong><br />
                    E-publication creation<br />
                    Create an e-presentation<br />
                    Creating spreadsheets and graphs<br />
                    File management and e-document production<br />
                    Database manipulation</p>

                    <p><strong>Certificate in Text Processing -</strong><br />
                    Business Presentations (Intermediate)<br />
                    Text Production (Intermediate)</p>
                </div>
                <div className="half content-box">
                    <header className="section-header">
                        <h1 className="section-title">Certifications</h1>
                    </header>
                    <div className="grid grid--certifications">
                        <div className="certificate">
                            <img className="image-contain" src="images/awards/interaction-design.svg" />
                            <p><strong>Interaction Design - UX Certified</strong></p>
                        </div>
                        <div className="certificate">
                            <img className="image-contain" src="images/awards/kentico-certified-developer.png" />
                            <p><strong>Kentico Certified Developer</strong></p>
                        </div>
                        <div className="certificate">
                            <img className="image-contain" src="images/awards/google-adwords-certified.png" />
                            <p><strong>Google AdWords Certified</strong></p>
                        </div>
                        <div className="certificate">
                            <img className="image-contain" src="images/awards/google-analytics-certified.png" />
                            <p><strong>Google Analytics Certified</strong></p>
                        </div>
                        <div className="certificate">
                            <img className="image-contain" src="images/awards/ucommerce.svg" />
                            <p><strong>Ucommerce Masterclass</strong></p>
                        </div>
                    </div>
                </div>
          </div>
      </section>
      <Awards />
      </Fragment>
    )
}

export default Knowledge;