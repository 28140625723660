import styled from 'styled-components';

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1C2222;
  height: 100vh;
  width: 100%;
  padding: 2rem;
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
  transition: all .2s ease-in-out;
  transform: translate3d(${({ open }) => open ? '0' : '100%'},0,0);
  overflow: hidden;

  .nav__mobile {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      padding: 0;
      margin-top: 7rem;
      list-style: none;

      @media (min-width: 768px) {
        align-items: center;
        text-align: center;
      }

      li {
        display: flex;
        flex-direction: column;
        color: white;
        margin-bottom: 2rem;

        span {
          color: #bbb;
        }
      }
    
      a {
        font-size: 2rem;
        font-family: 'Exo', sans-serif;
        font-weight: 600;
        padding: .5rem 0;
        letter-spacing: 2px;
        color: white;
        text-decoration: none;
        transition: color 0.3s linear;
    
        @media (min-width: 768px) {
          font-size: 5rem;
          padding: 3rem 4rem 0;
        }

        
    
        &:hover {
          color: ${({ theme }) => theme.primaryHover};
          color: #666;
        }
      }
  }

  footer {
    display: flex;
    width: 100%;
    color: white;

    ul {
        display: flex;
        padding: 0;
        list-style: none;
    }

    a {
      color: #f1672a;
      text-decoration: none;
    }
    
  }

  .footer__socials {
    margin-left: auto;
    font-size: 2rem;
  }
`;