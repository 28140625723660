import React from 'react';
const Numbers = ({websites, dimensions}) => {

    /*let number = 0;
    const websiteNumber = setInterval(()=> {
        console.log(number);
        number++
        if(number === websites.websites){
            console.log("what up");
            clearInterval(websiteNumber);
        }
    }, 100);*/


    /*
    let increaseNumber;
    if(number === websites){
        console.log("yes")
        console.log(websites)
        clearInterval(increaseNumber);
    }else {
        console.log("no")
        console.log(websites)
        increaseNumber = setInterval(()=> number++, 1000);
    }

    const increaseNumber = () => {

    }*/

    //console.log(websites)
    //console.log(websites);
    return (
        <section className="numbers section--padding background--darkgrey counter-top-skew">
            <div className="container">
                    <header className="section-header">
                        <h1 className="section-title">Here are some numbers for you</h1>
                    </header>
                    <p>Being in the web industry for so long I have had the pleasure working in every single department. This means my skills aren't limited to coding and those departments are included in my process when I develop a website or application. I incorporate all those departments into a piece of work. This varies from UX to SEO to structuring database tables.</p>
                    <ul className="stat-list">
                        <li><span>12</span>Years of Experience.</li>
    <li><span>{websites}</span> Websites and Applications built.</li>
                        <li><span>8</span> Awards.</li>
                        <li><span>13</span> Certifications.</li>
                    </ul>
            </div>
            <div className="angle-bottom" style={{borderLeftColor: '#20221D', borderLeftWidth: dimensions.width}}></div>
        </section>
    )
}

export default Numbers;