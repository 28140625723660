export const recommendations = [
    {
        name: "Sam Argyle",
        codename: "sam",
        title: "Managing Director",
        company: "Alternative Airlines",
        recommendation: "Sean is a very knowledgeable and experienced front-end developer and will be a huge asset to any tech company that employs him. Sean is also a good team leader and ran the Alternative Airlines' front-end team very successfully.",
        fontSize: "23"
    },
    {
        name: "Davs Howard",
        codename: "davs",
        title: "Technical Director",
        company: "Major Digital",
        recommendation: "When he joined our team, Sean brought a wealth of knowledge that allowed us to expand the scope of our projects that we would have been unable to do otherwise. Having worked with him for over three years his technical aptitude has continued to improve and expand and he became a reliable and trusted member of our team. In addition to his technical ability, his upbeat and refreshing personality makes him a delightful presence in the office every day."
    },
    {
        name: "Martyn McDermott",
        codename: "martyn",
        title: "UX and Visual Designer",
        company: "Major Digital",
        recommendation: "Sean is a very talented, calm and measured web developer and a valuable addition to any team. He has a keen design eye too, which, when coupled with his technical empathy, makes him very appreciated by our clients. I have been very impressed with his attitude, approach, problem solving abilities as well as his confidence to ask for help from his peers when required. In short, having Sean on my team gives me confidence.",
        fontSize: "17"
    }
];