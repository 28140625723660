import styled from 'styled-components';

export const StyledIntro = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;

    @media (min-width: 768px) {
        height: 100vh;
    }

    &::after {
        content: "";
        background-color: black;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: .4;
    }

    span {
        padding: .5rem 1rem;
        text-decoration: none;
        position: absolute;
        z-index: 2;
        bottom: 0;
        color: white;
        font-size: 1.25rem;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        width: 100%;
        padding-bottom: 4rem;
    }
`;