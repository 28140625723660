import React from 'react';

import { awards } from './data';

const Awards = props => (
    <section className="section section--padding background--white background--skew-right">
        <div className="container">
            <header className="section-header">
                <h2 className="section-title">Awards and Certifications</h2>
            </header>
            <div className="grid grid--awards">
                {awards.map((award, awardIndex) => {
                    const cardImage = '/images/awards/' + award.codename;
                    return (
                        <div className="card card--award" key={awardIndex}>
                            <img src={cardImage} alt={award.award} />
                        </div>
                    )
                })}
            </div>
        </div>
    </section>
)

export default Awards;