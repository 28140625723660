import React, { useState, useEffect } from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
  } from "react-router-dom";


const NotFound = () => {

    return (
        <section className="intro section--padding background--skew-left">
            <div className="container container--flex">
                <div className="intro__content">
                    <header className="section-header">
                        <h1 className="section-title">NotFound</h1>
                    </header>
                    <p>
                    I’m a highly motivated, hard working individual with the ability to work well within a team. I’m ambitious and highly-creative, with a keen eye for detail. I enjoy enhancing my skills and keeping up-to-date with the latest technologies. I produce new ideas and have the ability to multi task projects. With 11 years of working with colleagues and clients I have great communication skills, discussing and solving problems. Outstanding ability of using all types of IT software and hardware.
                    </p>
                    <ul className="bullet-list">
                    <li>11 Years building with HTML, CSS and JavaScript (inc JQuery)</li>
                    <li>10 Years working in agencies</li>
                    <li>6 Years building Kentico and WordPress</li>
                    <li>12 Years using Adobe Creative Suite</li>
                    <li>8 Years experience with SEO, SNI, GA, GTM, GSC</li>
                    <li>6 Months experience with React and UX</li>
                    </ul>
            </div>
            <div className="intro__image">
                <img src="images/sean1.jpg" />
            </div>
          </div>
      </section>
    )
}

export default NotFound;