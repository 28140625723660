import styled from 'styled-components';

export const StyledFeaturedTile = styled.div`
    position: relative;
    height: 32vh;
    background-size: cover;

    @media (min-width: 768px) {
        width: 100%;
        height: 50vh;
        margin: 0;
    }

    a {
        background-color: #f1672a;
        padding: .5rem 1rem;
        text-decoration: none;
        position: absolute;
        bottom: 0;
        color: white;
    }
`;