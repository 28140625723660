import React from 'react';

import { recommendations } from './data';

const Recommendation = props => (
    <section className="section section--padding">
        <div className="container">
            <header className="section-header">
                <h2 className="section-title center-text exo">Don't take my word for it</h2>
                
            </header>
            <div className="grid grid--recommendations">
                {recommendations.map((recommendation, recommendationIndex) => {
                    const cardImage = '/images/recommendations/' + recommendation.codename + '.jpg';
                    return (
                        <div className="card card--recommendation" key={recommendationIndex}>
                            <span className={`card__recommendation font-size-${recommendation.fontSize}`}>"{recommendation.recommendation}"</span>
                            <div className="card__profile">
                                <div className="card__image">
                                    <img src={cardImage} />
                                </div>
                                <div className="card__info">
                                    <span className="card__name">{recommendation.name}</span>
                                    <span className="card__title">{recommendation.title}</span>
                                    <span className="card__company">{recommendation.company}</span>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    </section>
)

export default Recommendation;