import React from 'react';

import { clients } from './data';

import { useMediaQuery } from 'react-responsive'; 

const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    return isDesktop ? children : null
  }
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
    return isTablet ? children : null
  }
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
  }
  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
  }



const Clients = ({dimensions}) => {

    const shuffle = (array) => {
        let currentIndex = array.length;
        let temporaryValue;
        let randomIndex;

        while(0 !== currentIndex) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }

        return array;
    }

    shuffle(clients)
    const shuffledClients = shuffle(clients.slice());
    return (
    <section className="section section--padding background--white counter-top-skew">
        <div className="container">
            <header className="section-header">
                <h2 className="section-title">I have had the pleasure working with</h2>
            </header>
            <div className="grid grid--clients">
                <Mobile>
                    {shuffledClients.slice(0,6).map((client, clientIndex) => {
                        const cardImage = '/images/clients/' + client.codename;
                        return (
                            <div className="card card--client" key={clientIndex}>
                                <img src={cardImage} alt={client.client} />
                            </div>
                        )
                    })}
                </Mobile>
                <Default>
                    {shuffledClients.map((client, clientIndex) => {
                        const cardImage = '/images/clients/' + client.codename;
                        return (
                            <div className="card card--client" key={clientIndex}>
                                <img src={cardImage} alt={client.client} />
                            </div>
                        )
                    })}
                </Default>
            </div>
        </div>
    </section>
)
            }
export default Clients;