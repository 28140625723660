import React, { useState, useEffect } from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
  } from "react-router-dom";

import { websites, getFilteredData } from './data';

import { FaUser, FaUserFriends, FaLink} from 'react-icons/fa';

const Website = ({page}) => {

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();
    console.log(query.get("build"));
    
    const [data] = useState(websites);
    const [filter, setFilter] = useState("");
    const [filteredData, setFilteredData] = useState(() =>
        getFilteredData(filter, data)
    );
    useEffect(
        function filterData() {
            setFilteredData(getFilteredData(filter, data));
        },
        [filter, data]
    );

    let category;

    if(query.get("build") === "single"){
        category =  false;
    }else if(query.get("build") === "team"){
        category = true;
    }else {
        category = null;
    }

    return (
        <section className={`section latest latest--${page}`}>
            <div className="container container--full">
                <header className="section-header page-header background--white">
                    <div className="section-info">
                        <h2 className="section-title">Websites</h2>
                        <p>I love what I do and take pride in every project I deliver. </p>
                        <div className="key"><FaUser /> Single builds <FaUserFriends /> Team builds <FaLink /> View website</div>
                    </div>
                    <div className="filter">
                        <input
                            min="1"
                            max="10"
                            value={filter}
                            onChange={e => setFilter(e.target.value)}
                            placeholder="Search..."
                        />
                        <ul className="filters">
                            <li><Link to="?build=all">All</Link></li>
                            <li><Link to="?build=single"><FaUser /> Single builds</Link></li>
                            <li><Link to="?build=team"><FaUserFriends /> Team builds</Link></li>
                        </ul>
                    </div>
                </header>
                <div className="grid grid--websites">
                    {filteredData.map((website, websiteIndex) => {
                    //{filteredData.filter(website => website.teambuild === category).map((website, websiteIndex) => {

                        return (
                            <div className={`card card--website card--${website.size}`} key={websiteIndex}>
                                {(website.video ?
                                    (<video autoPlay loop muted className="hero__video" poster="">
                                        <source src={`videos/${website.codename}.webm`} type="video/webm" />
                                        <source src={`videos/${website.codename}.mp4`} type="video/mp4" />
                                    </video>)
                                    :
                                    (<img src={`/images/websites/${website.codename}.jpg`} className={website.screenshot} alt={website.name} />)
                                )}
                                <div className="website__content">
                                    <span className="website__name">{website.name}</span>
                                    <span className="website__summary">{website.summary}</span>
                                    <span className="card__arrow"></span>
                                </div>
                                {(website.teambuild ?
                                    (<span className="website__tag"><FaUserFriends /></span>)
                                    :
                                    (<span className="website__tag"><FaUser /></span>)
                                )}
                                {(website.url ?
                                    (<a href={website.url} className="website__tag website__url"><FaLink /></a>)
                                    :
                                    (null)
                                )}
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}

export default Website;